import React from "react";
import CustomButton from "./CustomButton";
import { Link } from "react-router-dom";

const Footer = () => {
  const signup = "https://console.timelink.au/auth/signup";
  const login = "https://console.timelink.au/auth/login";

  const scrollToSection = async (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <footer className="bg-gray-100 py-8">
      <div className=" px-4 w-11/12 mx-auto">
        {/* Logo */}
        <div className="mb-6 md:mb-0">
          <img src="/images/logo.png" alt="Time Link Logo" className="h-8" />
        </div>
        <div className=" flex flex-wrap justify-between items-start mt-8">
          {/* Links */}
          <div className="flex flex-wrap gap-10">
            {/* Service Section */}
            <div>
              <h4 className="font-semibold text-gray-800 mb-4">SERVICE</h4>
              <ul className="space-y-2 ps-0">
                <li>
                  <a href="#" className="text-gray-600 no-underline">
                    Plans and Pricing
                  </a>
                </li>
              </ul>
            </div>

            {/* Company Section */}
            <div>
              <h4 className="font-semibold text-gray-800 mb-4">COMPANY</h4>
              <ul className="space-y-2 ps-0">
                <li>
                  <a href="#" className="text-gray-600 no-underline">
                    About Us
                  </a>
                </li>
                <li>
                  <Link
                    to="/term-condition"
                    className="text-gray-600 no-underline"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="text-gray-600 no-underline"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cookies-policy"
                    className="text-gray-600 no-underline"
                  >
                    Cookies Policy
                  </Link>
                </li>
              </ul>
            </div>

            {/* Help Section */}
            <div>
              <h4 className="font-semibold text-gray-800 mb-4">HELP</h4>
              <ul className="space-y-2 ps-0">
                <li>
                  <Link to="/contact" className="text-gray-600 no-underline">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <span
                    onClick={() => scrollToSection("faq")}
                    className="text-gray-600 no-underline"
                  >
                    FAQ
                  </span>
                </li>
                <li>
                  <a href="#" className="text-gray-600 no-underline">
                    Help Centre
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Signup Section */}
          <div className="mt-6 md:mt-0 md:ml-auto bg-gray-200 p-6 rounded-3xl shadow-md w-full md:w-1/3">
            <h4 className="font-semibold text-gray-800 mb-4">SIGN UP TODAY</h4>
            <p className="text-black text-sm mb-4">
              Get started with Time Link today and enhance your customer
              experience!
            </p>
            <div className="flex gap-4">
              <Link to={signup}>
                <CustomButton btntext={"SIGN UP"} />
              </Link>
              <Link to={login}>
                <CustomButton btntext={"BUSINESS LOG IN"} />
              </Link>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8  text-gray-500 text-sm">
          &copy; 2023 Time Link Tech PTY LTD
        </div>
      </div>
    </footer>
  );
};

export default Footer;
