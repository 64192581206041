// Import required modules from React and React Router
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import Home from "../pages/Home";
import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "../config/ScrollToTop";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermCondition from "../pages/TermCondition";
import CookiesPolicy from "../pages/CookiesPolicy";
import Contact from "../pages/Contact";
import ContactSale from "../pages/ContactSale";

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="h-screen flex justify-center items-center">
          {" "}
          <div className="p-2 bg-white rounded-2xl w-56 border-2 border-darkCyan animated-image">
            <img src="/images/logo.png" className="w-full" alt="" />
          </div>
        </div>
      }
    >
      <ScrollToTop />
      <Routes>
        {/* <Route path="/auth/login" element={<Login />} /> */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact-sale" element={<ContactSale />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
