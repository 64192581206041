import React, { useState } from "react";
import { CustomButton, Navbar, CustomCard } from "../components";
import { Link } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";
import { PiDeviceMobileSpeakerLight } from "react-icons/pi";
import { FaGoogle } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";
import { AiFillInfoCircle } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import { elitePlans, proPlans, startPlanas } from "../config/Plans";
import { faqsData } from "../config/FaqsData";
import FAQAccordion from "../components/FAQAccordion";
import Footer from "../components/Footer";
import { tabeArrowSvg } from "../components/TableArrowSvg";
// import notpadImage from "../../public/images/LeaveNotepads.png";

const Home = () => {
  const [email, setEmail] = useState("");
  const signup = "https://console.timelink.au/auth/signup";
  const [pricingTab, setPricingTab] = useState("monthly");

  const handleEmailSubmit = (event: any) => {
    event.preventDefault();
    const signupWithEmail = `${signup}?email=${email}`;
    window.open(signupWithEmail, "_blank");
  };
  const scrollToSection = async (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div className="lg:fixed lg:top-0 lg:start-0 lg:w-full  z-50">
        <Navbar />
      </div>
      <main className="bg-[#F6FBFF] lg:pt-[149px]">
        <br />
        <div id="home">
          <div className="w-10/12 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-2 ">
            <div className="flex items-center">
              <div>
                <h1 className="text-[40px] font-bold poppins">
                  Efficient Order Notifications
                </h1>
                <h1 className="text-4xl font-normal poppins">
                  for a Seamless Customer Experience
                </h1>
                <p className="text-lg lg:mt-5 md:mt-5 mt-3">
                  Send digital order notifications to your customers. TimeLink
                  is a business solution to replace traditional buzzers,
                  streamline customer satisfaction and harness the potential of
                  new age tech.
                </p>
                <div className="lg:flex md:flex gap-2 items-center lg:mt-5 md:mt-5 mt-3">
                  <form
                    onSubmit={handleEmailSubmit}
                    className=" lg:flex md:flex w-full  bg-white lg:rounded-full md:rounded-full rounded-xl p-2 flex-1"
                  >
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="focus:outline-none w-full lg:rounded-s-full md:rounded-s-full placeholder:text-black   p-3 text-sm "
                      placeholder="Enter email address"
                      required
                    />
                    <CustomButton
                      type="submit"
                      btntext="Get started"
                      rounded="lg:rounded-full md:rounded-full rounded-xl"
                      addclass="shrink-0 font-medium lg:w-auto md:w-auto w-full"
                    />
                  </form>
                  <CustomButton
                    handleClick={() => scrollToSection("how-it-works")}
                    rounded={"rounded-full"}
                    addclass={
                      "shrink-0 lg:mt-0 md:mt-0 mt-3 lg:w-auto md:w-auto w-full"
                    }
                    bg={"bg-white"}
                    textColor={"text-darkblue"}
                    border={" border-2 border-darkblue font-medium"}
                    btntext={"See How it Works"}
                  />
                </div>
              </div>
            </div>
            <img
              src="/images/home-herosection-img.png"
              className="w-full lg:mt-0 mt-4"
              alt="mage not found"
            />
          </div>
        </div>
      </main>
      {/* -herosection------------ */}
      <br />
      <br />
      <br />
      <div className="w-10/12 mx-auto py-4 bg-[#DEEDF9] rounded-3xl mt-5  ">
        <div className="lg:flex justify-end mt-2 items-center gap-4">
          <div className="lg:w-[75%] w-full p-3 ">
            <div>
              <h1 className="lg:text-[50px] md:text-5xl sm:text-4xl text-3xl font-semibold mt-lg-5">
                Queue Management That Doesn't Suck
              </h1>
              <br />
              <p className=" lg:mt-5 md:mt-5 mt-3 text-sm">
                Organize and control the flow of customers or visitors
                efficiently and effectively. With TablesReady, your queue lives
                online where you'll have the freedom to customize all of your
                SMS notifications, explore contactless check-ins & accept
                reservations online and in-person.
              </p>

              <div className="lg:mt-5 md:mt-5 mt-3 ">
                <Link to={signup} className="no-underline">
                  <CustomButton
                    rounded={"rounded-full"}
                    btntext={"Get Started for free"}
                    addclass="font-medium"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="lg:w-[15%] w-full">
            <div className="flex justify-end">
              <img
                src="/images/queueManagment-image.png"
                className="lg:w-full w-[30%]"
                alt="image not found"
              />
            </div>

            {/* <img
              src={computerMd}
              className="w-100 d-lg-none d-block"
              alt="image not found"
            /> */}
          </div>
        </div>
        <div className="flex justify-end px-4 mb-5">
          <p className="m-0 text-sm flex gap-2 items-center">
            <BsCheck2Circle className="text-darkblue shrink-0" />
            No app download required for your customers
          </p>
        </div>
      </div>
      {/* -------------cards----------- */}
      <br className="lg:flex md:flex  none" />
      <br />

      <br />
      <div className="w-10/12 mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 my-12 poppins">
        <CustomCard
          heading={"Streamline Queues Efficiently"}
          description={
            "Reduce wait times with automated queue management for faster service."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="41"
              height="29"
              viewBox="0 0 41 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.4167 0.166748H2.58332C2.10814 0.166748 1.65243 0.355513 1.31642 0.691515C0.980421 1.02752 0.791656 1.48324 0.791656 1.95841V12.7084C0.791656 13.1836 0.980421 13.6393 1.31642 13.9753C1.65243 14.3113 2.10814 14.5001 2.58332 14.5001H38.4167C38.8918 14.5001 39.3476 14.3113 39.6836 13.9753C40.0196 13.6393 40.2083 13.1836 40.2083 12.7084V1.95841C40.2083 1.48324 40.0196 1.02752 39.6836 0.691515C39.3476 0.355513 38.8918 0.166748 38.4167 0.166748ZM36.625 10.9167H4.37499V3.75008H36.625V10.9167ZM40.2083 19.8751C40.2083 20.3503 40.0196 20.806 39.6836 21.142C39.3476 21.478 38.8918 21.6667 38.4167 21.6667H2.58332C2.10814 21.6667 1.65243 21.478 1.31642 21.142C0.980421 20.806 0.791656 20.3503 0.791656 19.8751C0.791656 19.3999 0.980421 18.9442 1.31642 18.6082C1.65243 18.2722 2.10814 18.0834 2.58332 18.0834H38.4167C38.8918 18.0834 39.3476 18.2722 39.6836 18.6082C40.0196 18.9442 40.2083 19.3999 40.2083 19.8751ZM40.2083 27.0417C40.2083 27.5169 40.0196 27.9726 39.6836 28.3086C39.3476 28.6447 38.8918 28.8334 38.4167 28.8334H2.58332C2.10814 28.8334 1.65243 28.6447 1.31642 28.3086C0.980421 27.9726 0.791656 27.5169 0.791656 27.0417C0.791656 26.5666 0.980421 26.1108 1.31642 25.7748C1.65243 25.4388 2.10814 25.2501 2.58332 25.2501H38.4167C38.8918 25.2501 39.3476 25.4388 39.6836 25.7748C40.0196 26.1108 40.2083 26.5666 40.2083 27.0417Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Real-Time Updates"}
          description={
            "Keep customers informed with instant SMS and web notifications."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.5 29.75V12.25H14V29.75H10.5Z" fill="#1B4FF8" />
              <path d="M28 12.25V29.75H31.5V12.25H28Z" fill="#1B4FF8" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.5 5.25H38.5V36.75H3.5V5.25ZM7 8.75V33.25H35V8.75H7Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Flexible Integrations"}
          description={
            "Seamlessly integrate with your existing systems for smooth operations."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="41"
              height="29"
              viewBox="0 0 41 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.4167 0.166748H2.58332C2.10814 0.166748 1.65243 0.355513 1.31642 0.691515C0.980421 1.02752 0.791656 1.48324 0.791656 1.95841V12.7084C0.791656 13.1836 0.980421 13.6393 1.31642 13.9753C1.65243 14.3113 2.10814 14.5001 2.58332 14.5001H38.4167C38.8918 14.5001 39.3476 14.3113 39.6836 13.9753C40.0196 13.6393 40.2083 13.1836 40.2083 12.7084V1.95841C40.2083 1.48324 40.0196 1.02752 39.6836 0.691515C39.3476 0.355513 38.8918 0.166748 38.4167 0.166748ZM36.625 10.9167H4.37499V3.75008H36.625V10.9167ZM40.2083 19.8751C40.2083 20.3503 40.0196 20.806 39.6836 21.142C39.3476 21.478 38.8918 21.6667 38.4167 21.6667H2.58332C2.10814 21.6667 1.65243 21.478 1.31642 21.142C0.980421 20.806 0.791656 20.3503 0.791656 19.8751C0.791656 19.3999 0.980421 18.9442 1.31642 18.6082C1.65243 18.2722 2.10814 18.0834 2.58332 18.0834H38.4167C38.8918 18.0834 39.3476 18.2722 39.6836 18.6082C40.0196 18.9442 40.2083 19.3999 40.2083 19.8751ZM40.2083 27.0417C40.2083 27.5169 40.0196 27.9726 39.6836 28.3086C39.3476 28.6447 38.8918 28.8334 38.4167 28.8334H2.58332C2.10814 28.8334 1.65243 28.6447 1.31642 28.3086C0.980421 27.9726 0.791656 27.5169 0.791656 27.0417C0.791656 26.5666 0.980421 26.1108 1.31642 25.7748C1.65243 25.4388 2.10814 25.2501 2.58332 25.2501H38.4167C38.8918 25.2501 39.3476 25.4388 39.6836 25.7748C40.0196 26.1108 40.2083 26.5666 40.2083 27.0417Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Analytics & Reporting"}
          description={
            "Gain insights with detailed reports on customer flow and wait times."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="43"
              height="44"
              viewBox="0 0 43 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 33.8013L13.7261 20.3492C13.9303 19.9406 14.2828 19.6256 14.7116 19.4683C16.1618 18.9364 17.0333 19.1096 17.3262 19.9881L19.7549 27.2744C19.8994 27.7076 20.2038 28.0694 20.606 28.2857C21.9664 29.0173 22.8536 28.969 23.2677 28.1408L32.0833 10.5096M23.125 11.3411L30.7887 9.28763C31.0706 9.2121 31.3665 9.20655 31.651 9.27144C32.6537 9.50021 33.2296 9.89258 33.3786 10.4486L35.538 18.5078"
                stroke="#1B4FF8"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Customizable Alerts"}
          description={
            "Personalize notifications and alerts to fit your brand and customer needs."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.056 6.1041C25.97 5.8031 25.5826 5.7171 25.325 5.9321L20.982 10.2751C20.6376 10.6191 20.122 10.6191 19.7776 10.2751L16.7246 7.2221C16.3806 6.8781 16.3806 6.3621 16.7246 6.0181L21.1106 1.6321C21.3256 1.4171 21.2396 1.0301 20.9386 0.901098C20.208 0.729098 19.434 0.600098 18.66 0.600098C14.102 0.600098 10.447 4.5561 10.963 9.2001C11.049 9.9311 11.221 10.5761 11.479 11.2211L1.46 21.2831C0.299001 22.4441 0.299001 24.3791 1.46 25.5401C2.062 26.1421 2.836 26.4431 3.61 26.4431C4.384 26.4431 5.158 26.1425 5.76 25.5401L15.779 15.5211C16.424 15.7791 17.1124 15.9511 17.8 16.0371C22.487 16.5531 26.4 12.8981 26.4 8.3401C26.4 7.5661 26.271 6.7921 26.056 6.1041Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Multi-Location Support"}
          description={
            "Effortless queue management across multiple branches from a single platform."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="44"
              height="43"
              viewBox="0 0 44 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.3001 18.2391C35.7317 12.5416 32.2851 3.58325 21.6334 3.58325C10.9817 3.58325 7.53508 12.5416 6.96674 18.2391C4.97301 18.9786 3.65792 20.8492 3.6667 22.9333V25.4416C3.6667 28.2122 5.96502 30.4583 8.80008 30.4583C11.6351 30.4583 13.9334 28.2122 13.9334 25.4416V22.9333C13.9242 20.8942 12.6574 19.062 10.7251 18.2928C11.0917 14.9962 12.8884 7.16659 21.6334 7.16659C30.3784 7.16659 32.1567 14.9962 32.5234 18.2928C30.595 19.0637 29.3346 20.8971 29.3334 22.9333V25.4416C29.3414 27.3676 30.473 29.1204 32.2484 29.9566C31.4784 31.372 29.5167 33.2891 24.6951 33.8624C23.7312 32.4321 21.8665 31.8683 20.2446 32.5167C18.6226 33.1651 17.6968 34.8445 18.0351 36.5249C18.3733 38.2053 19.881 39.4166 21.6334 39.4166C23.0182 39.4091 24.2805 38.6398 24.8967 37.4278C32.7617 36.5499 35.2734 32.5903 36.0617 30.2612C38.1945 29.586 39.6288 27.6323 39.6001 25.4416V22.9333C39.6089 20.8492 38.2938 18.9786 36.3001 18.2391ZM10.2667 25.4416C10.2667 26.2332 9.6101 26.8749 8.80008 26.8749C7.99006 26.8749 7.33341 26.2332 7.33341 25.4416V22.9333C7.33341 22.4212 7.61296 21.948 8.06674 21.6919C8.52053 21.4359 9.07962 21.4359 9.53341 21.6919C9.9872 21.948 10.2667 22.4212 10.2667 22.9333V25.4416ZM33.0001 22.9333C33.0001 22.1416 33.6567 21.4999 34.4667 21.4999C35.2768 21.4999 35.9334 22.1416 35.9334 22.9333V25.4416C35.9334 26.2332 35.2768 26.8749 34.4667 26.8749C33.6567 26.8749 33.0001 26.2332 33.0001 25.4416V22.9333Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
      </div>

      <div className="w-10/12 mx-auto overflow-hidden">
        <div className="mt-20 text-center">
          <p className="inline-block rounded-full bg-[#F5F7F9] px-5 py-2 text-xs leading-tight text-[#A2B2C9] [&_a]:text-darkblue">
            Want to see ?
            <Link className="text-[#FCA7FF]" to="#">
              Schedule a call
            </Link>
          </p>
        </div>
        <br />
        <br />
        <br />
        <div className="animate-marquee whitespace-nowrap ">
          <h1 className="text-9xl	font-medium">
            {" "}
            POS Integration No Coding Required
          </h1>
        </div>
        <div className="animate-marquee-right whitespace-nowrap ">
          <h1 className="text-9xl mt-5 font-medium text-transparent outline-text">
            Download Required
          </h1>
        </div>
      </div>
      {/* No True Comparison.  */}
      <br />
      <br />
      <br />
      <div className="w-10/12 mx-auto overflow-hidden mt-12" id="features">
        <div className="flex justify-center ">
          <div className="border text-black font-medium border-gray-200 px-5 py-2 text-xs leading-tight  rounded-full">
            Unleash the Power of TimeLink
          </div>
        </div>
        <div className=" text-center">
          <h1 className="text-5xl font-semibold mt-3">No True Comparison.</h1>
          <p className="text-gray-500 mt-4">
            TimeLink stands alone in the field of queue management. With our
            innovative use of technology to streamline customer interaction and
            enhance operational efficiency, there simply isn’t a comparable
            alternative on the market. We offer a unique blend of real-time
            tracking, digital notifications, and customer engagement tools that
            traditional buzzer systems can’t match.
          </p>
        </div>
        <table className="w-full mt-12 rounded-2xl">
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th align="center">Others</th>
              <th align="center">
                <img src="/images/logo.png" className="w-24" alt="w8" />
              </th>
            </tr>
          </thead>
          <br />
          <tbody className="border rounded-2xl ">
            <tr className=" rounded-tl-xl rounded-tr-xl">
              <td className="p-2" colSpan={2}>
                Real-Time Order Tracking
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)] ">
              <td className="p-2" colSpan={2}>
                SMS & QR Code Notifications
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Data & Analytics
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Customisable Customer Engagement
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Cost Efficiency & Scalability
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Enhanced Hygiene
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>

            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Integrated Marketing Tools
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Device-Free Experience
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Collect Google Reviews
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Create Surveys
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                No app download for your customers.
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* =pricing==  */}
      <br />
      <br />

      <div className="w-9/12 mx-auto mt-5">
        <div className="flex justify-center ">
          <p className="inline-block rounded-full bg-[#F5F7F9] px-5 py-2 text-xs leading-tight text-[#A2B2C9] [&_a]:text-blue-700">
            Need a specific feature ?
            <Link className="text-[#FCA7FF]" to="#">
              Contact us
            </Link>
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="text-center my-5">
          <h4 className="text-lg text-darkblue uppercase">
            AFFORDABLE Pricing
          </h4>
          <h3 className="text-3xl font-bold mt-3">
            Upfront Pricing, No Hidden Fees
          </h3>
          <p className="text-gray-500 text-sm mt-3">
            No contracts or sales calls, Ever. <br /> Completely free to try -
            only creadit card.
          </p>
        </div>

        <div className=" my-28 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4  ">
          <div>
            <div
              className={`w-16 h-16 flex justify-center items-center bg-[#F4F6FF] rounded-full `}
            >
              <PiDeviceMobileSpeakerLight size={30} className="text-darkblue" />
            </div>
            <p className="font-bold mb-0 mt-5">Time Link</p>
            <h1 className="font-bold text-3xl mt-2">Essential Plan</h1>
            <p className="text-sm my-3">1,250* texts for one low price </p>

            <h1 className="text-darkblue font-bold mb-3 text-4xl">
              $89 <sub className="text-sm uppercase">/Month</sub>
            </h1>
            <CustomButton
              rounded={"rounded-full"}
              btntext={"plans and pricing"}
              addclass="w-1/2 mt-4"
            />
          </div>
          <div>
            <h5 className="font-bold">What's included:</h5>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">Digital Notification system</p>
            </div>

            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">Comprehensive Analytics & Insights</p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">1250 SMS Included</p>
            </div>

            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">Square POS Integration</p>
            </div>

            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                TimeLink Connect Device Included ($74.99 Value)
              </p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                1 FREE Accumulating Marketing Credit EACH Month ($75 Value){" "}
              </p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                Flexible cancellation - simply return the hardware.{" "}
              </p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                Email Support from Real, Live Humans{" "}
              </p>
            </div>
          </div>
        </div>
        <p className="mb-0 text-sm text-center">
          *Monthly messages are limited to 1,250. If monthly limit is exceeded -
          customers will still receives web-based and WhatsApp notifications
          (where applicable) Contact sales if you need more messages or if you
          have any questions.
        </p>
      </div>
      {/* Surveys  */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="bg-black relative py-10 overflow-hidden">
        <div className=" absolute -end-24 top-0 z-0">
          <svg
            width="240"
            height="240"
            viewBox="0 0 240 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_6957_115891)">
              <rect
                x="64"
                y="64"
                width="112"
                height="112"
                rx="56"
                fill="#F7C3FF"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_6957_115891"
                x="0"
                y="0"
                width="240"
                height="240"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="32"
                  result="effect1_foregroundBlur_6957_115891"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="absolute top-[50%] -start-24 z-0">
          <svg
            width="240"
            height="240"
            viewBox="0 0 240 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_6915_70617)">
              <rect
                x="64"
                y="64"
                width="112"
                height="112"
                rx="56"
                fill="#0A7CFF"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_6915_70617"
                x="0"
                y="0"
                width="240"
                height="240"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="32"
                  result="effect1_foregroundBlur_6915_70617"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="w-10/12 mx-auto text-white text-center my-4 relative z-20">
          <h1 className="text-5xl	font-semibold">Surveys</h1>
          <p className="text-gray-300 mt-3">
            Effortlessly measure satisfaction, gather actionable insights and
            enhance your services based on real customer feedback
          </p>
          <div className=" grid lg:grid-cols-3 md:grid-cols-3 text-start sm:grid-col-2 grid-cols-1 gap-4 mt-12">
            <div className="border border-white p-6 rounded-2xl h-fit">
              <p className="text-lg font-semibold">Quick-Start Template</p>
              <p className="text-sm mt-4  mb-8 opacity-75">
                Kick off your customer feedback journey with our quick-start
                templates, designed for immediate use and optimal engagement.
              </p>
            </div>
            <img src="/images/survay-image.png" alt="image not found " />
            <div className="border border-white p-6 rounded-2xl h-fit ">
              <p className="text-lg font-semibold">Custom Surveys</p>
              <p className="text-sm mt-4 mb-8 opacity-75">
                Easily create your own custom surveys to perfectly align your
                questions with your customers’ needs—your feedback, your way
              </p>
            </div>
          </div>
          <div className="lg:w-10/12 w-full mx-auto mt-12">
            <div className="bg-[rgba(242,241,244,1)] shadow rounded-full flex flex-wrap  items-center  gap-4 p-5 text-dark-emphasis">
              <span className="text-2xl">😂</span>
              <span className="text-2xl">😍</span>
              <span className="text-2xl">😮</span>
              <span className="text-2xl">🙌</span>
              <span className="text-2xl">👍</span>
              <span className="text-2xl">👎</span>
              <span className="h-6 w-[1.5px] bg-gray-600"></span>
              <FaGoogle className="text-gray-600 text-3xl" />
              <span className="text-2xl text-gray-600">
                Collect google reviews
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Marketing campaign
       */}
      <br />
      <br />
      <br />
      <div className="w-11/12 mx-auto bg-black py-4 my-12 rounded-[50px] ">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 p-10 place-items-center gap-3 ">
          <div className="flex justify-center ">
            <img
              src="/images/new-marketing-compain.png"
              className="w-11/12 "
              alt="image not found"
            />
          </div>

          <div className=" text-white">
            <div>
              <p className="font-lg opacity-75">
                You don’t need a million dollar budget to get new customers.{" "}
              </p>
              <h1 className=" text-5xl text-capitalize my-3">
                Marketing campaign
              </h1>
              <p className="font-lg opacity-75">
                Ready to turn ordinary into extraordinary? With TimeLink's
                Marketing Campaigns, you'll go from creating custom offers to
                unlocking TimeLink's powerful analytics—like a pro! Elevate your
                marketing game today and make waves in your industry!
              </p>
              <Link to={signup} className="no-underline">
                <CustomButton
                  btntext={"Get Started"}
                  addclass="mt-4"
                  padding="px-5 p-3"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ===  */}
      {/* ------------------Effortless Marketing Campaigns ----------------. */}
      <div className="w-10/12 mx-auto  py-4 lg:my-12 md:my-12 sm:my-6  ">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 p-10 place-items-center gap-3 ">
          <div>
            <p className="text-2xl font-semibold">
              Effortless Marketing Campaigns
            </p>
            <p className="text-base text-gray-500 mt-3">
              Leverage our intuitive platform to design and execute dynamic
              marketing campaigns that engage your customers and boost your
              brand awareness. Easily create personalized promotions and offers
              that resonate with your audience, driving loyalty and repeat
              business. Connect.
            </p>
            <Link to={signup} className="no-underline">
              <CustomButton
                btntext="Get started"
                addclass="mt-4"
                padding="px-5 p-3"
              />
            </Link>
          </div>
          <div className="flex justify-center">
            <img
              src="/images/EffortlessMarketingCampaigns.png"
              className="w-8/12"
              alt="imae not found"
            />
          </div>
        </div>
      </div>
      {/* ------------------Effortless Marketing Campaigns ----------------. */}
      <div className="w-11/12 mx-auto  py-4  lg:my-12 md:my-12 sm:my-6 ">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 p-10 place-items-center gap-3 ">
          <div className="flex justify-center">
            <img
              src="/images/businessconsole.png"
              className="w-10/12"
              alt="imae not found"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">
              Easy-to-use business console Panel
            </p>
            <p className="text-base text-gray-500 mt-3">
              Easily manage your business operations with our user-friendly
              business console. Set up marketing Campaigns, track analytics,
              create surveys and more!
            </p>
            <Link to={signup} className="no-underline">
              <CustomButton
                btntext="Get started"
                addclass="mt-4"
                padding="px-5 p-3"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="w-11/12 mx-auto" id="how-it-works">
        <header className="relative mx-auto mb-14 w-full text-center lg:w-1/2">
          <h6
            className="rounded-full text-sm inline-flex px-3.5 py-2"
            style={{
              background:
                "linear-gradient(90deg, rgba(0, 94, 255, 0.3) 10%, rgba(112, 136, 255, 0.3) 50%, rgba(228, 56, 255, 0.3) 100%)",
            }}
          >
            How it works
          </h6>

          <p className="text-header-p mx-auto text-[25px] font-semibold leading-7 lg:w-3/4 mt-5 opacity-75">
            So, how does it work?
          </p>
        </header>
        <div className="mx-auto w-full lg:w-10/12">
          <div className="relative lg:before:pointer-events-none lg:before:absolute lg:before:-top-2.5 lg:before:z-0 lg:before:h-[50px] lg:before:w-full lg:before:bg-gradient-to-r lg:before:from-transparent lg:before:via-[#E1EDFB] lg:before:to-transparent">
            {/* <figure
              className="absolute end-0 top-10 hidden -translate-y-full lg:block"
              aria-hidden="true"
            >
              <img
                className="animate-bounce [animation-duration:4s]"
                src="/images/logo.png"
                alt="Robot Image"
                width={78}
                height={149}
              />
            </figure> */}
            <div className="grid-cols-3 mb-20 grid gap-5 max-lg:grid-cols-1 lg:gap-20">
              <div className="group relative z-1 flex flex-col">
                <div className="relative mb-16 lg:before:absolute lg:before:-end-36 lg:before:start-8 lg:before:top-1/2 lg:before:h-px lg:before:bg-black/5 lg:group-last:before:content-none">
                  <span className="size-8 relative inline-grid place-content-center rounded-full border border-black/5 text-xs text-heading-foreground">
                    1
                  </span>
                </div>
                <h5 className="mb-4 mt-1 font-semibold">
                  Setup and Integration
                </h5>
                <p className="mb-0 text-gray-500">
                  Businesses start by signing up for TimeLink, choosing their
                  subscription, and integrating the system with into your
                  existing Square POS or by using the tablet application. You
                  can create surveys, marketing campaigns and customise settings
                  to align with your specific operational flow.
                </p>
              </div>
              <div className="group relative z-1 flex flex-col">
                <div className="relative mb-16 lg:before:absolute lg:before:-end-36 lg:before:start-8 lg:before:top-1/2 lg:before:h-px lg:before:bg-black/5 lg:group-last:before:content-none">
                  <span className="size-8 relative inline-grid place-content-center rounded-full border border-black/5 text-xs text-heading-foreground">
                    2
                  </span>
                </div>
                <h5 className="mb-4 mt-1 font-semibold">Order Management</h5>
                <p className="mb-0 text-gray-500">
                  As orders come in, staff input them into TimeLink via the
                  tablet or POS, which generates a unique QR code and NFC Code
                  for each order. This code is displayed on the TimeLink Connect
                  device, where it can be scanned. Staff can monitor order
                  status and manage the queue directly from the dashboard.
                </p>
              </div>
              <div className="group relative z-1 flex flex-col">
                <div className="relative mb-16 lg:before:absolute lg:before:-end-36 lg:before:start-8 lg:before:top-1/2 lg:before:h-px lg:before:bg-black/5 lg:group-last:before:content-none">
                  <span className="size-8 relative inline-grid place-content-center rounded-full border border-black/5 text-xs text-heading-foreground">
                    3
                  </span>
                </div>
                <h5 className="mb-4 mt-1 font-semibold">Notify and Engage</h5>
                <p className="mb-0 text-gray-500">
                  Once an order is ready, the staff can notify customers with a
                  single tap, sending an alert through the web-based application
                  and directly to your customers phone number.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="mt-20 text-center">
            <p className="inline-block rounded-full bg-[#F5F7F9] px-5 py-2 text-xs leading-tight text-[#A2B2C9] [&_a]:text-blue-700">
              Want to see?
              <a className="text-[#FCA7FF]" href="#">
                Start For Free
              </a>
            </p>
          </div> */}
        </div>
      </div>

      <section className="w-11/12 mx-auto" id="pricing">
        <div className="text-center">
          <h2 className="mb-5 text-4xl font-semibold">Flexible Pricing.</h2>
          <p className=" mx-auto lg:w-1/2 text-gray-500">
            We have tailored a variety of plans to fit your budget, ensuring
            that you can leverage TimeLink.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="my-5  inline-flex rounded-full text-sm gap-2 items-centerrounded-full border  p-3">
            <div
              className={`${pricingTab === "monthly" ? "bg-gray-100" : ""
                } p-2 px-4 rounded-full`}
            >
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => setPricingTab("monthly")}
              >
                <div
                  className={
                    pricingTab === "monthly"
                      ? `text-transparent  bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500`
                      : ""
                  }
                >
                  Monthly
                </div>
              </div>
            </div>
            <div
              className={`${pricingTab === "yearly" ? "bg-gray-100" : ""
                } p-2 px-4 rounded-full`}
            >
              <div
                className="flex items-center justify-center cursor-pointer "
                onClick={() => setPricingTab("yearly")}
              >
                <div
                  className={
                    pricingTab === "yearly"
                      ? `text-transparent  bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500`
                      : ""
                  }
                >
                  Yearly
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-3 md:rid-cols-3 sm:rid-cols-2 rid-cols-1 gap-4">
          <div className="p-3">
            <div className="p-4 h-fit">
              <h5 className="mb-5 inline-flex rounded-full border px-3.5 py-1 text-sm font-medium">
                Starter
              </h5>
              <p className="font-heading text-[34px] font-semibold leading-none text-heading-foreground">
                <sup className="text-[22px]">$</sup>
                59
                <span className="mt-3 block text-base font-normal text-[#4A5C7380]">
                  Per Month
                </span>
              </p>
              <CustomButton
                btntext="Select Starter"
                addclass="w-full mt-3"
                bg="bh-white"
                border="border"
                textColor="text-gray-500"
              />
              <p className="mt-6 text-xs font-medium text-heading-foreground">
                Ideal for individuals starting with AI tools, offering a balance
                of text and image capabilities.
              </p>
              {startPlanas?.map((value, idx) => {
                return (
                  <div className="flex gap-1 items-center  text-gray-500 my-2">
                    <IoMdCheckmark className="shrink-0" />
                    <p className="text-sm ">{value?.name}</p>
                    <AiFillInfoCircle className="text-gray-300 shrink-0" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="p-3">
            <div className="p-4 h-fit">
              <h5 className="mb-5 inline-flex rounded-full border px-3.5 py-1 text-sm font-medium">
                Pro
              </h5>
              <p className=" font-heading text-[34px] font-semibold leading-none text-heading-foreground">
                <sup className="text-[22px]">$</sup>
                89
                <span className="mt-3 block text-base font-normal text-[#4A5C7380]">
                  Per Month
                </span>
              </p>
              <CustomButton
                btntext="Select Essential"
                addclass="w-full mt-3"
                bg="bh-white"
                border="border"
                textColor="text-gray-500"
              />
              <p className="mt-6 text-xs font-medium text-heading-foreground">
                Designed for professionals, this plan supports larger-scale
                projects with enhanced text and image resources.
              </p>
              {proPlans?.map((value, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex gap-1 items-center  text-gray-500 my-2"
                  >
                    <IoMdCheckmark className="shrink-0" />
                    <p className="text-sm ">{value?.name}</p>
                    <AiFillInfoCircle className="text-gray-300 shrink-0" />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="p-3 rounded-xl h-fit"
            style={{
              background:
                "linear-gradient(90deg, rgba(0, 94, 255, 0.3) 10%, rgba(112, 136, 255, 0.3) 45.5%, rgba(228, 56, 255, 0.3) 80%)",
            }}
          >
            <div className="bg-white p-4">
              <h5 className="mb-5 inline-flex rounded-full border px-3.5 py-1 text-sm font-medium">
                Elite
              </h5>
              <p className=" font-heading text-[34px] font-semibold leading-none text-heading-foreground">
                <sup className="text-[22px]">$</sup>
                109
                <span className="mt-3 mb-0 block text-base font-normal text-[#4A5C7380]">
                  Per Month
                </span>
              </p>
              <CustomButton btntext="Select Growth" addclass="w-full mt-3" />
              <p className="mt-6 text-xs font-medium text-heading-foreground">
                Perfect for businesses or power users, providing robust text and
                image allocations to tackle any project.
              </p>
              {elitePlans?.map((value, idx) => {
                return (
                  <div className="flex gap-1 items-center  text-gray-500 my-2">
                    <IoMdCheckmark className="shrink-0" />
                    <p className="text-sm ">{value?.name}</p>
                    <AiFillInfoCircle className="text-gray-300 shrink-0" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <CustomButton
            btntext={"Contact Sales"}
            rounded="rounded-lg"
            addclass="px-5 p-3"
          />
        </div>
      </section>
      {/* =====FAQS===  */}
      <br />
      <br />
      <div className="md:w-10/12 mx-auto " id="faq">
        <FAQAccordion faqs={faqsData} />
      </div>
      {/* -------------- Keep your data safe -------------- */}
      <div
        className="w-full  flex items-center justify-center p-5 mt-5 text-center 	bg-no-repeat	bg-center	 h-[90vh] text-white"
        style={{
          backgroundImage: `url(${"/images/LeaveNotepads.png"})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="w-10/12 mx-auto py-4 pb-8 text-auto">
          <h1 className="lg:text-5xl md:text-5xl text-4xl ">
            Leave the notepads, dirty buzzers and chaotic lobbies to the
            amateurs.
          </h1>
          <h5 className=" fw-normal">
            7-day free trial • cancel anytime • only creadit card required
          </h5>
          <Link to={signup} className="no-underline">
            <CustomButton
              rounded={"rounded-full"}
              addclass={"mx-auto mt-6 shadow-[0_46px_52px_0_#C9D6FF]"}
              btntext={"Start Your 7-Day Free Trial"}
            />
          </Link>
        </div>
      </div>
      {/* -------------- TimeLink for Enterprise -------------- */}
      <br />
      <br />
      <br />
      <div className="w-11/12 mx-auto bg-black py-6 my-12 rounded-3xl mt-5 text-center text-white">
        <br />
        <br />
        <div className="py-6">
          <h1 className="text-5xl">TimeLink for Enterprise</h1>
          <h5 className="opacity-75 fw-normal mt-3">
            TimeLink for Enterprise helps incorporate Timelink for{" "}
            <br className="d-lg-block d-none " />
            larger scale businesses.
          </h5>
          <Link to={signup} className="no-underline">
            <CustomButton
              addclass="mx-auto mt-6 "
              border="border-4 border-[rgba(85,89,221,0.4)]"
              btntext={<FiExternalLink />}
              icon={"Learn more"}
            />
          </Link>
        </div>
        <br />
        <br />
      </div>
      <br />
      <br />
      {/* ====  */}
      {/* -------------- Keep your data safe -------------- */}
      <div className="w-11/12  mx-auto bg-[#EFF0FF] py-6 my-12 rounded-3xl mt-5 text-center text-blue-950">
        <br />
        <br />
        <div className="py-4">
          <h1 className="text-5xl">Keep your data safe</h1>
          <h5 className="opacity-75 fw-normal mt-5">
            Enterprise-grade security to keep your data and your customer’s data
            <br className="d-lg-block d-none " />
            private and secure.
          </h5>
        </div>
        <br />
        <br />
      </div>

      <section
        className=" py-5"
        style={{
          background:
            "linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 55%, #EFF0FF 55%, #E0EFFB 100%)",
        }}
      >
        <div className=" w-10/12 mx-auto bg-white p-6 rounded-3xl shadow-[0px_2px_6px_0px_#c6c5c5] flex flex-wrap justify-between items-center">
          <h1 className="mb-0 text-3xl">TimeLink works wherever you do.</h1>
          <Link to={signup} className="no-underline">
            <CustomButton
              border="border-4 border-[rgba(85,89,221,0.4)]"
              btntext="Learn more"
            />
          </Link>
        </div>
      </section>
      {/* == fotter  */}
      <Footer />
    </>
  );
};

export default Home;
